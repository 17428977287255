import revive_payload_client_4sVQNw7RlN from "/application/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/application/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/application/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/application/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/application/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/application/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/application/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/application/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/application/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/application/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/application/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_W86eSGFqyZ from "/application/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import pwa_icons_Y6agwlnXZq from "/application/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/application/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import switch_locale_path_ssr_8BKffvaEi5 from "/application/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/application/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import primevue_plugin_egKpok8Auk from "/application/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/application/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_AOuQ1DYzjk from "/application/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import slideovers_X7whXyrIWR from "/application/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/application/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/application/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/application/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/application/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import apexcharts_client_Dd5Ebxj7XB from "/application/plugins/apexcharts.client.ts";
import sentry_client_shVUlIjFLk from "/application/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_client_W86eSGFqyZ,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  plugin_AOuQ1DYzjk,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  apexcharts_client_Dd5Ebxj7XB,
  sentry_client_shVUlIjFLk
]